.menu {
    cursor: pointer;
}
body hr.hr-header{position:relative;}
body hr.hr-header:after {
    border:1px solid rgb(221, 221, 221);
	color: #000;
	content: attr(data-text);
	display: inline-block;
	position: absolute;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	top: 50%;
	background: #FFF;
	text-transform: uppercase;
	letter-spacing: .2rem;
	padding: 2px 20px;
	text-align: center;
}