$color-stop-size: 14px;
$color-stop-size-active: 22px;


.gradientpicker {
  width: 100%;

  .palette {
    width: 100%;
    width: calc(100% - 8px);
    margin: 0 auto;
    margin-bottom: 15px;
    position: relative;

    svg {
      border-radius: 9px;
      overflow: hidden;
      height: 18px;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAHUlEQVQ4jWNgYGAQIYAJglEDhoUBg9+FowbQ2gAARjwKARjtnN8AAAAASUVORK5CYII=');
    }

    .stopholder {
      position: absolute;
      left: #{$color-stop-size-active / 2};
      top: 0;
      width: calc(100% - #{$color-stop-size-active});
      height: 100%;

      &:hover {
        cursor: copy;
      }
    }

    .stop {
      border-radius: 50%;
      border: 2px solid #fff;
      position: absolute;
      left: 0;
      top: -2px;
      width: $color-stop-size;
      height: $color-stop-size;
      margin: 4px;
      cursor: pointer;
      margin-left: -#{$color-stop-size / 2};

      &.active {
        border: 4px solid #fff;
        box-shadow: 0 0 5px rgba(0,0,0,0.5);
        width: $color-stop-size-active;
        height: $color-stop-size-active;
        margin: 0;
        margin-left: -#{$color-stop-size-active / 2};
      }

      &.active::after {
        position: absolute;
        content: "";
        width: 22px;
        height: 22px;
        left: -5px;
        top: -5px;
        border-radius: 50%;
        border: 1px solid rgba(0, 0, 0, 0.3);
      }
    }
  }


  .orientation {
    margin-top: 10px;
  }

  .setting {
    width: 100%;
    clear: both;
    display: inline-block;
    margin-bottom: 10px;

    .label,
    .function {
      width: 50%;
      float: left;
      box-sizing: border-box;
    }

    .label {
      padding-top: 3px;
      padding-right: 10px;
    }
  }


  .button-2-options {
    width: 100%;
    height: 30px;
    background-color: #e4e4e4;
    border-radius: 6px;
    overflow: hidden;

    .option {
      width: 50%;
      height: 30px;
      line-height: 26px;
      text-align: center;
      float: left;
      font-size: 13px;
      cursor: pointer;
      overflow: hidden;
      border: 2px solid transparent;

      &.active {
        background-color: #fff;
        background-clip: padding-box;
        border-radius: 6px;
        box-sizing: border-box;
        color: #333;
      }
    }
  }


  .button-amount {
    width: 100%;
    height: 30px;
    background-color: #e4e4e4;
    border-radius: 6px;
    text-align: center;
    position: relative;

    * {
      box-sizing: border-box;
    }

    input {
      float: left;
      width: 36%;
      border: 0;
      margin: 0 auto;
      background-color: transparent;
      text-align: center;
      height: 30px;
      font-size: 13px;
    }

    div {
      float: left;
      width: 32%;
      height: 30px;
      line-height: 26px;
      background-color: #fff;
      background-clip: padding-box;
      border-radius: 6px;
      color: #333;
      border: 2px solid transparent;
      font-size: 16px;
    }

    .increase,
    .decrease {
      cursor: pointer;
    }

  }


  input[type="range"] {
    width: 100%;
  }

}